<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">Electrochemistry Experiment: Results - Part A</h2>

      <p class="mb-5">
        Fill in the measured voltage for the <b>voltaic</b> cell generated from each combination of
        half-cells in the table below. As the electrodes are unique for each half-cell, the
        half-cell will be denoted simply by the material used for the electrode. Please use the link
        <a
          href="https://openstax.org/books/chemistry-2e/pages/l-standard-electrode-half-cell-potentials"
          rel="noopener noreferrer"
          target="_blank"
          >here</a
        >
        for a list of standard reduction potentials for the determination of the
        <stemble-latex content="$\text{E}^\circ_\text{cell,theor}$" /> values.
        <b>Please express ALL percent error values to 3 significant figures.</b>
      </p>

      <v-simple-table>
        <thead>
          <tr>
            <td><stemble-latex content="$\text{Cell}$" /></td>
            <td>
              <stemble-latex content="$\text{Half-Cell 1}$" />
            </td>
            <td class="my-2 py-2 mx-0 py-2">
              <stemble-latex content="$\text{Half-Cell 2}$" />
            </td>
            <td class="my-2 py-2 mx-0 py-2" style="text-align: center">
              <stemble-latex content="$\text{E}^\circ_\text{cell, exp}$" />
              <stemble-latex content="$\text{ (V)}$" />
            </td>
            <td class="my-2 py-2 mx-0 py-2" style="text-align: center">
              <stemble-latex content="$\text{E}^\circ_\text{cell, theor}$" />
              <stemble-latex content="$\text{ (V)}$" />
            </td>
            <td class="my-2 py-2 mx-0 py-2" style="text-align: center">
              <stemble-latex content="$\%\,\text{Error}$" />
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <stemble-latex content="$1$" />
            </td>
            <td>
              <stemble-latex content="$\ce{Cu(s)}$" />
            </td>
            <td>
              <stemble-latex :content="electrode1Cell2" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.Ecell1"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.EcellTheoretical1"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.pctError1"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$2$" />
            </td>
            <td>
              <stemble-latex content="$\ce{Pb(s)}$" />
            </td>
            <td>
              <stemble-latex :content="electrode2Cell2" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.Ecell2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.EcellTheoretical2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.pctError2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$3$" />
            </td>
            <td>
              <stemble-latex content="$\ce{Mg(s)}$" />
            </td>
            <td>
              <stemble-latex :content="electrode3Cell2" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.Ecell3"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.EcellTheoretical3"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.pctError3"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$4$" />
            </td>
            <td>
              <stemble-latex :content="electrode4Cell1" />
            </td>
            <td>
              <stemble-latex :content="electrode4Cell2" />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.Ecell4"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.EcellTheoretical4"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="centered-input px-2 py-2 mx-0 my-2">
              <calculation-input
                v-model="inputs.pctError4"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-2 mt-5">
        b) Please draw and upload a cell diagram of <b>CELL {{ cellNumber.value }}</b> AND your data
        sheet for this part of the experiment using the upload field below.
      </p>

      <p class="mb-3">
        <v-file-input v-model="attachments" multiple accept="application/pdf" />
      </p>

      <p class="mb-1" style="font-weight: bold">
        Have multiple files to upload? Click
        <a
          href="https://support.stemble.ca/attach-multiple-files "
          rel="noopener noreferrer"
          target="_blank"
        >
          here
        </a>
        to find out how.
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import ChemicalSymbolInput from '@/tasks/components/inputs/ChemicalSymbolInput.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';
import STextarea from '@/common/components/STextareaShort.vue';

export default {
  name: 'ChemMcMA5_ResultsAV2',
  components: {
    STextarea,
    LatexNumber,
    NumberValue,
    ChemicalSymbolInput,
    ChemicalNotationInput,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Ecell1: null,
        Ecell2: null,
        Ecell3: null,
        Ecell4: null,
        EcellTheoretical1: null,
        EcellTheoretical2: null,
        EcellTheoretical3: null,
        EcellTheoretical4: null,
        pctError1: null,
        pctError2: null,
        pctError3: null,
        pctError4: null,
      },
      attachments: [],
    };
  },
  computed: {
    cellNumber() {
      return this.taskState.getValueBySymbol('cellNumber').content;
    },
    cell1Number() {
      return this.taskState.getValueBySymbol('cell1Number').content;
    },
    cell2Number() {
      return this.taskState.getValueBySymbol('cell2Number').content;
    },
    cell3Number() {
      return this.taskState.getValueBySymbol('cell3Number').content;
    },
    cell4Number() {
      return this.taskState.getValueBySymbol('cell4Number').content;
    },
    electrode1Cell2() {
      if (this.cell1Number.value === 1) {
        return '$\\ce{Pb(s)}$';
      } else if (this.cell1Number.value === 2) {
        return '$\\ce{Mg(s)}$';
      } else if (this.cell1Number.value === 3) {
        return '$\\ce{Ni(s)}$';
      } else if (this.cell1Number.value === 4) {
        return '$\\ce{Sn(s)}$';
      } else if (this.cell1Number.value === 5) {
        return '$\\ce{Zn(s)}$';
      }
    },
    electrode2Cell2() {
      if (this.cell2Number.value === 6) {
        return '$\\ce{Mg(s)}$';
      } else if (this.cell2Number.value === 7) {
        return '$\\ce{Ni(s)}$';
      } else if (this.cell2Number.value === 8) {
        return '$\\ce{Zn(s)}$';
      }
    },
    electrode3Cell2() {
      if (this.cell3Number.value === 10) {
        return '$\\ce{Ni(s)}$';
      } else if (this.cell3Number.value === 11) {
        return '$\\ce{Sn(s)}$';
      } else if (this.cell3Number.value === 12) {
        return '$\\ce{Zn(s)}$';
      }
    },
    electrode4Cell1() {
      if (this.cell4Number.value === 13 || this.cell4Number.value === 14) {
        return '$\\ce{Ni(s)}$';
      } else if (this.cell4Number.value === 15) {
        return '$\\ce{Sn(s)}$';
      }
    },
    electrode4Cell2() {
      if (this.cell4Number.value === 13) {
        return '$\\ce{Sn(s)}$';
      } else if (this.cell4Number.value === 14 || this.cell4Number.value === 15) {
        return '$\\ce{Zn(s)}$';
      }
    },
  },
};
</script>
<style scoped></style>
